import User from "../../types/User";
import AuthUserInfo, { AuthUser } from "../../types/AuthUserInfo";

/**
 * Take the user object from Firebase (from either the Firebase admin SDK or
 * or the client-side Firebase JS SDK) and return a consistent AuthUser object.
 * @param {Object} firebaseUser - A decoded Firebase user token or JS SDK
 *   Firebase user object.
 * @return {Object|null} AuthUser - The user object.
 * @return {String} AuthUser.uid - The user's ID
 * @return {String} AuthUser.email - The user's email
 * @return {Boolean} AuthUser.emailVerified - Whether the user has verified their email
 */
export const createAuthUser = (
  firebaseUser?: firebase.User | null
): AuthUser | null => {
  if (!firebaseUser || !firebaseUser.uid) {
    return null;
  }
  return {
    uid: firebaseUser?.uid,
    email: firebaseUser?.email || "",
    emailVerified: firebaseUser?.emailVerified || false,
  };
};

/**
 * Create an object with an AuthUser object and AuthUserToken value.
 * @param {Object} firebaseUser - A decoded Firebase user token or JS SDK
 *   Firebase user object.
 * @param {String} firebaseToken - A Firebase auth token string.
 * @return {Object|null} AuthUserInfo - The auth user info object.
 * @return {String} AuthUserInfo.AuthUser - An AuthUser object (see
 *   `createAuthUser` above).
 * @return {String} AuthUser.token - The user's encoded Firebase token.
 */
export const createAuthUserInfo = (AuthUserInfo: {
  firebaseUser?: firebase.User | null;
  token?: string | null;
}): AuthUserInfo | null => {
  const AuthUser = createAuthUser(AuthUserInfo?.firebaseUser);
  if (!AuthUser || !AuthUserInfo?.token) return null;

  return {
    AuthUser,
    token: AuthUserInfo.token,
  };
};

export const getDisplayName = (user?: User) => {
  if (!user) return "";
  if (user.anonymous) return "Anonymous User";

  return user.firstName + (user.lastName ? " " + user.lastName : "");
};
