import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import initFirebase from "./initFirebase";
import { setSession } from "./firebaseSessionHandler";
import { createAuthUserInfo } from "./user";
import JsCookie from "js-cookie";

initFirebase();

// https://benmcmahen.com/using-firebase-with-react-hooks/

// Defaults to empty AuthUserInfo object.
export const AuthUserInfoContext = React.createContext(createAuthUserInfo());

export const useAuthUserInfo = () => {
  return React.useContext(AuthUserInfoContext);
};

// Returns a Firebase JS SDK user object.
export const useFirebaseAuth = () => {
  const [state, setState] = useState(() => {
    const firebaseUser = firebase.auth().currentUser;
    return {
      initializing: true,
      firebaseUser,
      token: null,
    };
  });

  async function onChange(firebaseUser) {
    // Call server to update session.
    const token = firebaseUser ? await firebaseUser.getIdToken() : null;

    if (token) {
      // Set auth stuff first so that the data will be ready when the next page loads
      // setState({ firebaseUser, token });
      await setSession(token);
      setState({
        firebaseUser,
        token,
        initializing: false,
      });
    } else {
      await setSession();

      setState({
        firebaseUser,
        token: null,
        initializing: false,
      });

      // Remove them manually just in case
      JsCookie.remove("express:sess");
      JsCookie.remove("express:sess.sig");
    }
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(onChange);

    // Unsubscribe to the listener when unmounting.
    return () => unsubscribe();
  }, []);

  return state;
};
