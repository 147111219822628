import React, { useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../components/theme";
import { useFirebaseAuth } from "../utils/auth/hooks";
import { createAuthUserInfo } from "../utils/auth/user";
import useSWR, { SWRConfig } from "swr";
import { swrFetcher } from "../utils/fetcher";
import { useRouter } from "next/router";
import User from "../types/User";
import AppLayout from "../components/AppLayout";
import { AppProps } from "next/app";
import authPageRedirect from "../utils/authPageRedirect";
import JsCookie from "js-cookie";
import AuthUserInfo from "../types/AuthUserInfo";
import * as Sentry from "@sentry/node";

Sentry.init({
  enabled: process.env.MY_NODE_ENV !== "development",
  dsn: process.env.SENTRY_DSN,
  environment: process.env.MY_NODE_ENV,
});

const noSidebarPages = ["/signin", "/setup", "/"];

const swrConfig = {
  revalidateOnFocus: false,
  dedupingInterval: 3600 * 1000,
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  // Remove the server-side injected CSS.
  // if (typeof window !== "undefined") {
  //   const jssStyles = window.document.querySelector("#jss-server-side");
  //   jssStyles?.parentElement?.removeChild(jssStyles);
  // }

  const {
    firebaseUser,
    token: firebaseToken,
    initializing,
  } = useFirebaseAuth();
  const AuthUserInfoFromClient = createAuthUserInfo({
    firebaseUser,
    token: firebaseToken,
  });

  let AuthUserInfoFromSession: AuthUserInfo | null = null;
  if (typeof window !== "undefined") {
    try {
      const parsedCookie = JSON.parse(
        atob(JsCookie.get("express:sess") || "") || ""
      );

      AuthUserInfoFromSession = createAuthUserInfo({
        firebaseUser: parsedCookie.decodedToken,
        token: parsedCookie.token,
      });
    } catch (e) {
      console.log(e);
    }
  }

  const AuthUserInfo = AuthUserInfoFromSession || AuthUserInfoFromClient;
  const token = AuthUserInfo?.token;

  const router = useRouter();

  const { data: user } = useSWR<User>(
    ["currentUser", token],
    () =>
      swrFetcher({
        token: token,
        endpoint: "currentUser",
        method: "GET",
      }),
    swrConfig
  );

  useEffect(() => {
    const redirectUrl = authPageRedirect({
      pathname: router.pathname,
      token: AuthUserInfo?.token,
      user,
    });
    if (redirectUrl) router.replace(redirectUrl);
  }, [user, router.route, router.pathname, AuthUserInfo?.token]);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SWRConfig value={swrConfig}>
          {authPageRedirect({
            pathname: router.pathname,
            token: AuthUserInfo?.token,
            user,
          }) ? null : noSidebarPages.includes(router.route) ? (
            <Component AuthUserInfo={AuthUserInfo} {...pageProps} />
          ) : (
            <AppLayout user={user} loading={initializing || !token}>
              {({ onMenuToggle }: { onMenuToggle: () => void }) => (
                <Component
                  AuthUserInfo={AuthUserInfo}
                  {...pageProps}
                  onMenuToggle={onMenuToggle}
                />
              )}
            </AppLayout>
          )}
        </SWRConfig>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default MyApp;
