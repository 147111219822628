import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  badge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.primary.main,
    borderRadius: 500,
    width: 30,
    height: 30,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    padding: theme.spacing(1),
  },
  darkBadge: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  small: {
    width: 20,
    height: 20,
    fontSize: 12,
  },
}));

type Props = {
  children?: React.ReactNode;
  inverse?: boolean;
  small?: boolean;
  className?: string;
};

export default ({ children, className, inverse, small }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.badge,
        inverse && classes.darkBadge,
        small && classes.small,
        className
      )}
    >
      {children}
    </div>
  );
};
