import PageContext from "../types/PageContext";
import { onboarded } from "./getMissingInformation";
import User from "../types/User";
import JsCookie from "js-cookie";

export const redirectIfSSR = ({
  path,
  ctx,
}: {
  path: string;
  ctx: PageContext;
}) => {
  if (typeof window === "undefined") {
    ctx.res?.writeHead(302, { Location: path }).end();
    return true;
  }
  return false;
};

export default ({
  pathname,
  token,
  user,
}: {
  pathname: string;
  token?: string;
  user?: User;
}): string => {
  if (typeof window === "undefined") return "";

  if (!token) {
    if (pathname !== "/signin") {
      return "/signin";
    }
  } else if (!onboarded(user) && pathname !== "/setup") {
    return "/setup";
  } else if (pathname === "/signin" || pathname === "/") {
    const activeReferee = JsCookie.get("activeReferee");
    if (activeReferee === "true") {
      return "/jobSeeking/referrals";
    }
    return "/referring/jobSeekers";
  }

  return "";
};
