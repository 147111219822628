import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

const config = {
  apiKey: process.env.FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  appId: process.env.FIREBASE_APP_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export default () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);

    if (process.env.MY_NODE_ENV === "production" && typeof window !== "undefined") {
      firebase.analytics();
    }
  }
};
