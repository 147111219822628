import React, { ReactNode, useState, useEffect } from "react";
import Router, { useRouter } from "next/router";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import PersonOutlineIcon from "@material-ui/icons/Person";
import MailIcon from "@material-ui/icons/Mail";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PeopleOutline from "@material-ui/icons/People";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import ErrorIcon from "@material-ui/icons/Error";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import logout from "../utils/auth/logout";
import getMissingInformation from "../utils/getMissingInformation";
import User from "../types/User";
import {
  Collapse,
  SvgIcon,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import Centered from "./Centered";
import MessagingTabBadge from "./MessagingTabBadge";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  toolbar: theme.mixins.toolbar,
  topLeftLogo: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    height: 56,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(5),
  },
  dashboardContainer: {
    paddingTop: 0,
  },
  drawerContent: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  drawerHeader: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      1
    )}px ${theme.spacing(2)}px`,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
  },
  drawerItems: {
    flex: 1,
    flexWrap: "nowrap",
    color: theme.palette.common.white,
  },
  drawerMenuIcon: {
    color: theme.palette.common.white,
  },
  drawerMainItems: {
    flex: 1,
  },
}));

type tabSectionItemType = {
  text: string;
  defaultParams?: () => string;
  IconComponent: typeof SvgIcon;
  route?: string;
  badge?: (props: any) => any;
  onClick?: Function;
};

type tabs = {
  header: string;
  items: tabSectionItemType[];
}[];

const drawerItems = [
  {
    header: "ME",
    items: [
      {
        text: "My Profile",
        IconComponent: PersonOutlineIcon,
        route: "/profile",
      },

      {
        text: "Messages",
        IconComponent: MailIcon,
        route: "/messages",
        badge: (user?: User) => (
          <MessagingTabBadge currentUserUid={user?.uid} />
        ),
      },
    ],
  },
  {
    header: "JOB SEEKER",
    items: [
      {
        text: "Referrals",
        IconComponent: PersonAddIcon,
        route: "/jobSeeking/referrals",
      },
      {
        text: "Settings",
        IconComponent: AccountBoxIcon,
        route: "/jobSeeking/settings",
        badge: (user?: User) =>
          getMissingInformation(user).includes("target_companies") ? (
            <Tooltip
              title="You will not be visible to referrers until you complete your profile"
              placement="right"
            >
              <ErrorIcon style={{ color: "white" }} />
            </Tooltip>
          ) : null,
      },
    ],
  },
  {
    header: "REFERRER",
    items: [
      {
        text: "Find Job Seekers",
        IconComponent: PeopleOutline,
        route: "/referring/jobSeekers",
        defaultParams: () => "?sortBy=joinDate&interestedInMyCompany=true",
      },
      {
        text: "Referrals",
        IconComponent: PersonAddIcon,
        route: "/referring/referrals",
      },
      {
        text: "Settings",
        IconComponent: AccountBoxIcon,
        route: "/referring/settings",
        badge: (user?: User) =>
          user && !user.companyEmail ? (
            <Tooltip
              title="Verify your company email to get access to job seekers"
              placement="right"
            >
              <ErrorIcon style={{ color: "white" }} />
            </Tooltip>
          ) : null,
      },
    ],
  },
] as tabs;

const bottomTabs = {
  items: [
    {
      text: "Account Settings",
      IconComponent: SettingsIcon,
      route: "/account",
    },
    {
      text: "Log out",
      IconComponent: ExitToAppIcon,
      onClick: logout,
    },
  ],
};

type Props = {
  children: ({ onMenuToggle }: { onMenuToggle: () => void }) => ReactNode;
  loading: boolean;
  user?: User;
};

const Layout = ({ children, user, loading }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [jobSeekersTabsOpen, setJobSeekersTabsOpen] = useState(false);
  const [referringTabsOpen, setReferringTabsOpen] = useState(false);

  const router = useRouter();

  const openReferringTabs = () => {
    setJobSeekersTabsOpen(false);
    setReferringTabsOpen(true);
  };

  const openJobSeekingTabs = () => {
    setJobSeekersTabsOpen(true);
    setReferringTabsOpen(false);
  };

  useEffect(() => {
    if (drawerItems[1].items.find((item) => item.route === router.route)) {
      openJobSeekingTabs();
    } else if (
      drawerItems[2].items.find((item) => item.route === router.route)
    ) {
      openReferringTabs();
    } else if (!jobSeekersTabsOpen && !referringTabsOpen) {
      if (user?.activeReferee) {
        openJobSeekingTabs();
      } else {
        openReferringTabs();
      }
    }
  }, [router.route]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderTabSection = (
    { header, items }: { header?: string; items: tabSectionItemType[] },
    isOpen?: boolean,
    toggle?: Function
  ) => {
    const list = (
      <List>
        {items.map(
          ({ text, IconComponent, route, badge, defaultParams, onClick }) => {
            const handleTabClick = () => {
              if (onClick) {
                onClick();
              } else {
                Router.push(route + (defaultParams ? defaultParams() : ""));
              }
            };

            return (
              <ListItem
                selected={route === router.route}
                button
                key={route || text}
                onClick={handleTabClick}
              >
                <ListItemIcon>
                  <IconComponent className={classes.drawerMenuIcon} />
                </ListItemIcon>
                <ListItemText primary={text} />
                {badge && badge(user)}
              </ListItem>
            );
          }
        )}
      </List>
    );

    return (
      <div key={header}>
        {header && (
          <ListItem
            // https://github.com/mui-org/material-ui/issues/14971 This prop is broken
            button={!!toggle as true}
            className={classes.drawerHeader}
            onClick={toggle ? () => toggle(!isOpen) : undefined}
          >
            <ListItemText
              primary={header}
              primaryTypographyProps={{ variant: "h6" }}
            />
            {toggle && (isOpen ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
        )}

        {toggle ? (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {list}
          </Collapse>
        ) : (
          list
        )}
      </div>
    );
  };

  const drawer = (
    <div className={classes.drawerContent}>
      <div className={classes.toolbar}>
        <a href="https://www.refsy.io" target="_blank">
          <img className={classes.topLeftLogo} src="/images/refsy_white.svg" />
        </a>
      </div>
      <Grid
        className={classes.drawerItems}
        container
        direction="column"
        justify="space-between"
      >
        <div className={classes.drawerMainItems}>
          {renderTabSection(drawerItems[0])}
          {renderTabSection(
            drawerItems[1],
            jobSeekersTabsOpen,
            setJobSeekersTabsOpen
          )}
          {renderTabSection(
            drawerItems[2],
            referringTabsOpen,
            setReferringTabsOpen
          )}
        </div>

        {renderTabSection(bottomTabs)}
      </Grid>
    </div>
  );

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {loading ? (
          <Centered>
            <CircularProgress />
          </Centered>
        ) : (
          children({ onMenuToggle: handleDrawerToggle })
        )}
      </main>
      {/* <style global jsx>{`
        html,
        body,
        body > div:first-child,
        div#__next,
        div#__next > div,
        div#__next > div > div {
          height: 100%;
        }
      `}</style> */}
    </div>
  );
};

export default Layout;
