import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/database";
import { RecipientToChat } from "types/Message";
import NotificationBadge from "./NotificationBadge";

type Props = {
  currentUserUid?: string;
};

export default ({ currentUserUid }: Props) => {
  const [unreadChatsCount, setUnreadChatsCount] = useState(0);

  useEffect(() => {
    if (!currentUserUid) return;

    const dbRef = firebase.database().ref(`/users/${currentUserUid}`);

    dbRef.on("value", (snapshot) => {
      const recipientToChat: RecipientToChat | null = snapshot.val();

      if (!recipientToChat) return;

      const keys = Object.keys(recipientToChat);

      if (!keys || keys.length === 0) return; 

      let count = 0;
      keys.forEach((k) => {
        if (!!recipientToChat[k].hasUnreadMessages) {
          count++;
        }
      });

      setUnreadChatsCount(count);
    });
  }, [currentUserUid]);

  if (!unreadChatsCount) return null;

  return (
    <NotificationBadge inverse small>
      {unreadChatsCount}
    </NotificationBadge>
  );
};
