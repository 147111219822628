// From:
// https://github.com/zeit/next.js/blob/canary/examples/with-firebase-authentication/pages/index.js

import fetch from "isomorphic-unfetch";

export const setSession = (token: string) => {
  // Log in.
  if (token) {
    return fetch("/api/login", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "same-origin",
      body: JSON.stringify({ token }),
    }).then((r) => r.json());
  }

  // Log out.
  return fetch("/api/logout", {
    method: "POST",
    credentials: "same-origin",
  });
};
